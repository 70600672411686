.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
}

.label {
  font-size: 18px;
}

.input {
  margin: 1px 1px 1px 17px;
  font-size: 16px;
  font-family: monospace;
  padding: 10px 16px;
  width: 346px;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid #aaaaaa 1px;
  border-radius: 10px;
  color: #b4b4b4;
  outline: none;
}

.input:focus {
  margin: 0 0 0 16px;
  border: solid rgb(10, 132, 255) 2px;
}

.input.invalid.touched {
  border-color: #6e0000 !important;
}

.errorMessage {
  background-color: #3b0000;
  padding: 16px;
  color: #ff6c6c;
  border-radius: 16px;
  margin-top: 16px;
}
