.container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  width: 100%;
}

.vinLabel {
  color: #55575c;
  font-size: 14px;
}

.vinContainer {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

.vin {
  font-size: 22px;
  font-weight: 500;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dcdfe8;
  margin-top: 14px;
  margin-bottom: 24px;
}

.row {
  width: 100%;
  display: flex;
  align-items: center;
}

.col {
  width: 50%;
  padding: 10px 12px;
  font-size: 14px;
  min-height: 100%;
}

.dropdownStatus {
  padding: 0;
}

.col:first-child {
  color: #55575c;
}

.emptyValue {
  color: #55575c;
}

.deductionBadge {
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 2px;
  background-color: #e0e0e0;
  width: fit-content;
}

.deductionBadge:not(:first-child) {
  margin-top: 6px;
}
