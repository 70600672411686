.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legend {
  display: flex;
  align-items: center;
  gap: 4px;
}

.icon {
  width: 23px;
  height: 23px;
  padding: 3px;
  border-radius: 4px;
  background-color: #7496b4;
}

.vin {
  background-color: lightgray;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
}
