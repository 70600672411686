.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 40px 40px 40px;
}

.pageTitle {
  font-size: 30px;
  font-weight: lighter;
  padding-bottom: 24px;
}

.card {
  max-width: 600px;
}

.tabPanel {
  width: 100%;
}

.tabPanelContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardContent {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
