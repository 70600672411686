:root {
  --navbar-height: 78px;
}

.pageContainer {
  width: 100dvw;
  height: 100dvh;
  padding-top: var(--navbar-height);
}

.navbarContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--navbar-height);
  padding: 30px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: #1c1c1c;
}

.logo {
  height: 100%;
}
