.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  align-items: center;
  justify-content: center;
  height: 400px;
  position: relative;
}

.info {
  margin-bottom: 24px;
  text-align: center;
}

.example {
  margin-bottom: 24px;
}

.dropzoneContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
}

.dropzoneText {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
}

.dropzoneDescription {
  opacity: 0.5;
  font-size: 14px;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlay > .outlineButton {
  background-color: white;
}

.overlayMessage {
  text-align: center;
  padding-bottom: 24px;
}

.overlayMessage.error {
  color: #6e0000;
}

.resetButton {
  margin-top: 24px;
}

.progressBarContainer {
  width: 80%;
}

.progressBarPercentage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 10px;
  padding-right: 2px;
  font-family: monospace;
}

.progressBar {
  width: 100%;
  height: 10px;
  border-radius: 99999px;
  background-color: #e0e0e0;
  border: solid #25262b 1px;
  position: relative;
  overflow: hidden;
}

.progressBarFill {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #25262b;
}
