.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.thContent {
  display: flex;
  align-items: center;
  color: black;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
}

.tbody {
  position: relative;
}

.tfooter {
  position: relative;
  width: 100%;
}

.addDamageContainer {
  width: 100%;
  padding: 14px;
  border-width: 0 1px 1px 1px;
  border-color: #e5e7eb;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addDamageButton {
  font-weight: 400;
}

.actionIconTh {
  width: 16px;
}
