.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.drawerOffset {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: padding-left var(--dashboard-drawer-transition);
  padding-left: calc(
    var(--dashboard-drawer-collapsed-width) + var(--dashboard-drawer-border-width)
  );
}

.drawerOffset.openDrawer {
  padding-left: calc(var(--dashboard-drawer-expanded-width) + var(--dashboard-drawer-border-width));
}

.pageContainer {
  width: 100%;
  height: 100%;
  overflow: scroll;
}
