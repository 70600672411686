:root {
  --loader-tr-height: 300px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 40px 40px 40px;
}

.actionIconTh {
  width: 0.1%;
  white-space: nowrap;
}

.clickableTh {
  cursor: pointer;
}

.clickableTh:hover {
  background-color: #efefef;
}

.clickableTh:active {
  background-color: #e8e8e8;
}

.thContent {
  display: flex;
  align-items: center;
}

.tbody {
  position: relative;
}

.loaderTr {
  height: var(--loader-tr-height);
  border-width: 0 1px 1px 1px;
  border-color: #e5e7eb;
  border-style: solid;
}

.loaderTr,
.loaderTr > td {
  position: absolute;
  inset: 0 0 0 0;
  background-color: transparent !important;
}

.emptyFiller {
  height: 200px;
}

.messageTd {
  padding: 16px 40px !important;
  font-size: 16px !important;
}

.messageTd.error {
  color: #6e0000;
}

.sortIcon {
  margin-left: 10px;
}

.actionIconTd {
  display: flex;
  align-items: center;
  justify-content: end;
}

.actionIconTd > button {
  margin: 0 6px;
}

.noData {
  opacity: 0.5;
  font-style: italic;
}

.paginationContainer {
  width: 100%;
  padding: 14px;
  border-width: 0 1px 1px 1px;
  border-color: #e5e7eb;
  border-style: solid;
  display: flex;
  align-items: center;
  justify-content: end;
  font-weight: 500;
}

.paginationContainer.loading {
  margin-top: var(--loader-tr-height);
}

.rowsPerPageSelect {
  margin-left: 6px;
  margin-right: 20px;
  border-radius: 4px;
}

.rowsPerPageSelect:hover {
  background-color: #efefef;
}

.rowsPerPageSelect:active {
  background-color: #e8e8e8;
}

.rowsPerPageSelect input {
  width: 70px;
  padding: 0 10px;
}

.rowsPerPageSelectRightSection {
  pointer-events: none;
}

.rowsPerPageSelectItem[data-selected],
.rowsPerPageSelectItem[data-selected]:hover {
  background-color: #25262b;
}

.of {
  padding: 0 5px;
}

.previousPage {
  margin-left: 20px;
  margin-right: 6px;
}

.inspectionReviewContainer {
  height: 80dvh;
  width: 100%;
}

.tempMessage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 30px;
}

.modalHeader {
  opacity: 0.7;
}

.modalHeader > h2 {
  width: 95%;
}
