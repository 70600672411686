.datePickerWrapper {
  width: 200px;
  overflow: hidden;
  margin-left: 14px;
}

.datePickerInput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkbox {
  margin-bottom: 14px;
}

.checkbox > input {
  cursor: pointer;
}

.buttonsContainer {
  padding-top: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
