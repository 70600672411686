html,
body,
#root,
.app-container {
  height: 100dvh;
  width: 100%;
}

:root {
  --dashboard-drawer-border-width: 1px;
  --dashboard-drawer-expanded-width: 250px;
  --dashboard-drawer-collapsed-width: 63px;
  --dashboard-drawer-transition: 0.3s ease-out;
}

body {
  margin: 0;
  background-color: #000000;
  font-family: sans-serif;
  color: white;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
