.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  position: relative;
  max-width: 400px;
}

.input {
  padding-bottom: 14px;
}

.odometerContainer {
  display: flex;
  flex-direction: row;
}

.odometerInput {
  width: 50%;
}

.mileageUnit {
  width: 90px;
}

.km {
  font-size: 14px;
  opacity: 0.5;
  padding-right: 24px;
}

.submitContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  font-family: monospace;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.overlayMessage {
  text-align: center;
  padding-bottom: 24px;
}

.overlayMessage.error {
  color: #6e0000;
}

.resetButton {
  margin-top: 24px;
}

.tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip > span {
  padding-left: 6px;
}
