.container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--dashboard-drawer-expanded-width);
  transition: all var(--dashboard-drawer-transition);
  border-right: solid var(--dashboard-drawer-border-width) #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container:not(.open) {
  transform: translateX(
    calc(var(--dashboard-drawer-collapsed-width) - var(--dashboard-drawer-expanded-width))
  );
}

.container:not(.open) .content {
  opacity: 0;
}

.toggle {
  margin: 14px;
  align-self: end;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transition: opacity var(--dashboard-drawer-transition);
  opacity: 1;
}

.navlinkRoot:hover {
  background-color: #efefef;
}

.navlinkRoot:active {
  background-color: #e8e8e8;
}

.separator {
  width: 90%;
  height: 1px;
  margin: 16px 0;
  background-color: #d5d5d5;
}

.countError {
  color: #6e0000;
}

.loader {
  padding: 7px 0;
}
