.container {
  display: flex;
  justifycontent: center;
}

.exteriorButton {
  color: black;
  width: 200px;
  border-width: 1px 0px 1px 1px;
  border-radius: 100px 0px 0px 100px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
}

.interiorButton {
  color: black;
  width: 200px;
  border-width: 1px 1px 1px 1px;
  border-radius: 0px 100px 100px 0px;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.1px;
}
