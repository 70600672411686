.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title {
  font-size: 30px;
  padding-bottom: 24px;
  font-weight: lighter;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;
}

.filters > .left {
  display: flex;
  align-items: center;
  flex: 1;
}

.filters > .right {
  display: flex;
  align-items: center;
}

.inputRoot {
  flex: 1;
  max-width: 500px;
}

.inputWrapper {
  flex: 1;
  border-bottom: solid black 1px;
}

.inputActive {
  font-weight: bold;
}

.middleButton {
  margin-left: 14px;
}

.datePickerWrapper {
  width: 200px;
  overflow: hidden;
}

.datePickerInput {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.statusesButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.statusCheckbox {
  pointer-events: none;
}
